import React, { Component } from 'react'; //different
import SweetAlert from 'react-bootstrap-sweetalert';
//import Paoyingchub from './game/paoyingchub';
import jwt_decode from "jwt-decode";
import instance from "./axios-instance";
//import './build/web/igame-index-lobby-wm/style.a57427aa.css';
import { isMobile } from 'react-device-detect';
import NavMenu from './navmenu';
import Swal from 'sweetalert2'
import Systems from "./SystemFunction";
import moment from 'moment';
class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categorylist: [],
            rewardlist: [],
            activePage: 1,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: false, // Indicates in progress state of login form
            pagecount: 1,
            username: null,
            userid: null,
            point: null,
            products_page_count: 1,
            loginstate: false,
            firstname: null,
            lastname: null,
            alert: null,
            typepage: null
        }
        // this.handleClick = this.handleClick.bind(this);
        // this.handlePageChange = this.handlePageChange.bind(this);
        // this.hideAlert = this.hideAlert.bind(this);

    }


    callpercent(gameid) {
        //console.log(this.state.typepage)
        const date = new Date();
        let number = 30 + Math.floor(Math.random() * (100 - 30));
        var tokenx = localStorage.getItem('percentgame');
        let tagpercentlist = [];
        let tagpercent = [];
        if (tokenx != null) {
            const listx = JSON.parse(tokenx);
            var listIndex = listx.filter(x => x.id === gameid);
            // console.log(57, listIndex);
            if (listIndex.length == 0) {
                //  console.log(gameid, 59);
                let list = {
                    id: gameid,
                    percent: number,
                    datetime: date
                }
                listx.push(list)
                tagpercent.push(listIndex)
                localStorage.setItem('percentgame', JSON.stringify(listx))
            } else {
                tagpercent = listIndex
                var created = new Date();
                const startDate = moment(listIndex[0].datetime);
                const timeEnd = moment(created);
                const diff = timeEnd.diff(startDate);
                const diffDuration = moment.duration(diff);
                // console.log(777, diffDuration.minutes(), gameid)
                if (diffDuration.minutes() > 30) {
                    listx.filter(x => x.id === gameid).map(function (obj) {
                        obj.number = number
                        obj.datetime = date
                        return obj
                    })
                    localStorage.setItem('percentgame', JSON.stringify(listx))
                    console.log("finish", gameid)
                }
            }
        }
        else {
            let list = {
                id: gameid,
                percent: number,
                datetime: date
            }
            tagpercent.push(list)
            tagpercentlist.push(list)
            localStorage.setItem('percentgame', JSON.stringify(tagpercentlist))
        }
        console.log(92, this.state.typepage)
        if (this.state.typepage != "casino" && this.state.typepage != "sport") {
            let pop = (
                <div>
                    <div className="progress position-progress mt-1 position-progress-g"
                        role="progressbar"
                        aria-label="Example with label"
                        aria-valuenow={tagpercent[0].percent}
                        aria-valuemin={0}
                        aria-valuemax={100}
                    >
                        <div
                            className="progress-bar bg-rate-persent text-web"
                            style={{
                                width: parseInt(tagpercent[0].percent) + "%",
                                background: (tagpercent[0].percent > 0 && tagpercent[0].percent < 50) ? "rgb(254, 215, 25)" : (tagpercent[0].percent >= 50 && tagpercent[0].percent < 70) ? " rgb(2, 119, 189)" : "rgb(37, 155, 36)"
                            }}
                        />
                    </div>
                    <div className="text-win-rate">
                        <small className="text-size-win-rate">
                            อัตราชนะ
                        </small>
                        <b className="p-1 text-size-win-rate-number">
                            {tagpercent[0].percent}%
                        </b>
                    </div>
                </div>
            )
            return pop
        }
        else {
            return ""
        }


    }




    redeem = (e, gameIdx, partner) => {
        var userAgent = navigator.userAgent
        //  console.log(userAgent);
        this.checklogin();
        if (!this.state.loginstate) {
            //////console.log(5465656);
            return document.getElementById("loginmode").click();
        }
        if (this.state.username !== null) {
            instance.post("/api/v1/tablauncher", {
                gameId: gameIdx,
                partner: partner,
                System: Systems,
                playerUsername: this.state.userid,
                isMobile: isMobile,
                demo: false
            })
                .then((res) => {
                    if (res.data.status === 200) {
                        //////console.log(res.data.message);
                        var test = res.data.message.launcher
                        if (test !== undefined)
                            if (/iPad|iPhone|Macintosh|iPod/.test(userAgent) && !window.MSStream) {
                                window.location.href = test.mobile;
                            }
                            else {

                                if (!isMobile) window.open(test.desktop, '_blank', 'height=1000,width=1000');
                                else window.open(test.mobile);
                            }
                        else if (test === undefined) {
                            Swal.fire({
                                title: 'error!',
                                text: 'ขณะนี้เกมมีปัญหากรุณารอซักครู่',
                                icon: 'error',
                                confirmButtonText: 'ตกลง'
                            })
                        }
                    }
                    else {
                        //  this.CheckThisGoalError();
                    }
                });
        }
        else {

        }


    }

    checklogin() {
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            //////console.log(decoded);
            this.setState({
                userid: decoded.message.playerid,
                username: decoded.message.playerid,
                firstname: decoded.message.firstname,
                lastname: decoded.message.lastname,
                point: decoded.message.Point,
                loginstate: true,
            });
        }
        else {
            this.setState({
                loginstate: false,
            });
        }
    }

    componentDidMount() {
        this.checklogin();
        this.checkonline();
    }

    checkonline() {
        //////console.log(1);
        const search = window.location.href;
        const params = new URLSearchParams(search);
        const space = search.split("/")
        this.setState({
            typepage: space[4].replace("#", ""),
        });
        instance.post("/api/v1/tabgamelist", {
            state: "999",
            System: Systems,
            partner: space[5].replace("#", ""),
            game: space[4].replace("#", ""),
        })
            .then((res) => {
                if (res.data.status === 200) {
                    this.setState({
                        categorylist: res.data.message
                    });
                }
                else {
                    //  this.CheckThisGoalError();
                }
            });
    }
    addDefaultSrc(ev, listitem) {
        const origin = listitem.split("http");
        //  console.log("img", origin.length )
        if (origin.length > 1) {
            return listitem;
        }
        else {
            return "https://ardmhzelxcmj.ocrazeckyunc.com" + listitem

        }

    }
    // copyToClipboard = (e) => {
    //     e.preventDefault();
    //     var categorylist = this.state.categorylist;
    //     let searchStr = this.state.formData["sec"];
    //     //////console.log(searchStr);
    //     //////console.log(categorylist);
    //     if (searchStr !== "") {
    //         let matches = categorylist.filter(t => t.description.toLowerCase().includes(searchStr));
    //         //////console.log(matches);
    //         this.setState({
    //             categorylist: matches,
    //         });
    //     } else {
    //         this.checkonline();
    //     }
    // };

    // handleInputChange = (event) => {
    //     const target = event.target;
    //     const value = target.value;
    //     const name = target.name;
    //     //////console.log(value);
    //     let { formData } = this.state;
    //     formData[name] = value;
    //     this.setState({
    //         formData: formData,
    //     });
    // }


    render() {
        if (this.state.categorylist.length > 0) {
            return (
                <section className="x-index-top-container">
                    <div className="-index-inner-wrapper" style={{ backgroundImage: "url(/build/web/igame-index-lobby-wm/img/index-bg.jpg)" }}>
                        <div className="d-lg-none">
                            <div className="x-feed-news-header">
                                <div className="-feed-news-inner-wrapper" data-animatable="fadeInUp" data-delat={200}>
                                    <div className="-icon-container">
                                        <i className="fas fa-volume-up" />
                                    </div>
                                    <div className="-track">
                                        <div className="-track-item -duration-normal-content">
                                            Bet4you.BET คาสิโนออนไลน์ สล็อตออนไลน์ ที่ให้คุณสนุกได้ทุกรูปแบบ
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="-games-provider-wrapper" >
                            <NavMenu />
                            <div className="-games-index-page"  >
                                <h2 className="-heading-sub-title">Bet4you Casino คาสิโนออนไลน์เต็มรูปแบบ ตื่นตาตื่นใจไปกับทุกการเดิมพัน</h2>
                                <div className="x-category-provider js-game-scroll-container js-game-container">
                                    <div className="-games-list-container container">
                                        <nav className="nav-menu" id="navbarProvider">
                                            <ul className="nav nav-pills">
                                                {this.state.categorylist.map(listitem => (
                                                    <li key={listitem.id} id={listitem.id} className="nav-item -game-casino-macro-container">
                                                        <a
                                                            href={() => false}
                                                            onClick={(e) => this.redeem(e, listitem.gameId, listitem.partner)}
                                                            className="nav-link js-account-approve-aware"
                                                            rel="nofollow noopener"
                                                        >
                                                            <div className="x-game-list-item-macro js-game-list-toggle -big " data-status>
                                                                <div className="-inner-wrapper">
                                                                    <picture>
                                                                        <img
                                                                            src={this.addDefaultSrc(null, listitem.image)}
                                                                            className="-cover-img lazyload img-fluid" alt="dream-gaming" width={364} height={231} />
                                                                    </picture>
                                                                    <div className="-overlay">
                                                                        <div className="-overlay-inner">
                                                                            <div className="-wrapper-container">
                                                                                <button href="#" className="-btn -btn-play js-account-approve-aware"  >
                                                                                    <i className="fas fa-play" />
                                                                                    <span className="-text-btn">เข้าเล่น</span>
                                                                                </button>
                                                                                <button
                                                                                    id="loginmode"
                                                                                    href="#loginModal"
                                                                                    className="-btn -btn-play js-account-approve-aware"
                                                                                    data-toggle="modal"
                                                                                    data-target="#loginModal"
                                                                                    style={{ display: "none" }}
                                                                                >
                                                                                    <i className="fas fa-play" />
                                                                                    <span className="-text-btn">เข้าเล่น</span>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="progress-web position-progress-data p-2">
                                                                    <div>
                                                                        {this.callpercent(listitem.gameId)}
                                                                        <div>
                                                                            <div className="name-progress-circle-number text-name text-ellipsis d-flex justify-content-between align-items-center">
                                                                                <div>
                                                                                    <b className="text-ellipsis">
                                                                                        {listitem.description}
                                                                                    </b>
                                                                                </div>
                                                                                <div>
                                                                                    <svg
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        width={16}
                                                                                        height={16}
                                                                                        fill="currentColor"
                                                                                        className="bi bi-star"
                                                                                        viewBox="0 0 16 16"
                                                                                    >
                                                                                        <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.56.56 0 0 0-.163-.505L1.71 6.745l4.052-.576a.53.53 0 0 0 .393-.288L8 2.223l1.847 3.658a.53.53 0 0 0 .393.288l4.052.575-2.906 2.77a.56.56 0 0 0-.163.506l.694 3.957-3.686-1.894a.5.5 0 0 0-.461 0z" />
                                                                                    </svg>
                                                                                </div>
                                                                            </div>
                                                                            <div className="color-c-g">{listitem.partner}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >

            );
        } else {

        }
    }
}
export default App;
