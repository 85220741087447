import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import instance from "./axios-instance";
import Systems from "./SystemFunction";
// import jwt_decode from "jwt-decode";

const WheelComponent = ({
    segments,
    segColors,
    disablex,
    Prefix = Systems,
    winningSegment,
    userid,
    round,
    onFinished,
    primaryColor = 'black',
    contrastColor = 'white',
    buttonText = 'Spin',
    isOnlyOnce = false,
    size = 230,
    upDuration = 100,
    downDuration = 1000,
    fontFamily = 'proxima-nova'
}) => {
    let currentSegment = ''
    let currentSegments = []
    let isStarted = false
    const [isFinished, setFinished] = useState(false)
    let timerHandle = 0
    let rounds = round
    let userids = userid
    const timerDelay = segments.length
    let angleCurrent = 0
    let angleDelta = 0
    let canvasContext = null
    let maxSpeed = Math.PI / `${segments.length}`
    const upTime = segments.length * upDuration
    const downTime = segments.length * downDuration
    let spinStart = 0
    let frames = 0
    const width = 1000
    const height = 800
    const centerX = 350
    const centerY = 250
    useEffect(() => {
        handlewheel(3);
        wheelInit()
        setTimeout(() => {
            window.scrollTo(0, 1)
        }, 0)
    }, [])

    const wheelInit = () => {
        initCanvas()
        wheelDraw()
    }

    const initCanvas = () => {
        let canvas = document.getElementById('canvas')

        // console.log(navigator)
        if (navigator.userAgent.indexOf('MSIE') !== -1) {
            canvas = document.createElement('canvas')
            canvas.setAttribute('width', width)
            canvas.setAttribute('height', height)
            canvas.setAttribute('id', 'canvas')
            document.getElementById('wheel').appendChild(canvas)
        }
        canvas.addEventListener('click', spin, false)
        canvasContext = canvas.getContext('2d')
    }

    const handlewheel = async (stype) => {
        try {
            let payload = {
                type: stype,
                userid: userids,
                System: Prefix
            };
            await instance.post("/api/v1/postwheelspin", payload,).then((res) => {
                //  console.log(res.data.message[0].Point);
                rounds = res.data.message[0].Point;
            }).catch((error) => {
                console.log(error)
            });
        }
        catch (error) {
            console.log(error)
        }

    };

    const spin = async () => {
        await handlewheel(3);
        //  rounds = await handlewheel(3);
        console.log(97, rounds, disablex);
        if (disablex) {
            if (rounds > 0) {
                isStarted = true
                if (timerHandle === 0) {
                    spinStart = new Date().getTime()
                    // maxSpeed = Math.PI / ((segments.length*2) + Math.random())
                    maxSpeed = Math.PI / segments.length
                    frames = 0
                    timerHandle = setInterval(onTimerTick, timerDelay)
                    rounds =rounds- 1
                }
            }
            else {
                Swal.fire({
                    title: 'Warning!',
                    text: 'คุณยังไม่มี Point ในการหมุน',
                    icon: 'warning',
                    confirmButtonText: 'ตกลง'
                })
            }
        } else {
            Swal.fire({
                title: 'Warning!',
                text: 'คุณยังไม่ได้ Login',
                icon: 'warning',
                confirmButtonText: 'ตกลง'
            })
        }

    }
    const onTimerTick = () => {
        frames++
        draw()
        const duration = new Date().getTime() - spinStart
        let progress = 0
        let finished = false
        if (duration < upTime) {
            progress = duration / upTime
            angleDelta = maxSpeed * Math.sin((progress * Math.PI) / 2)
        } else {
            if (winningSegment) {
                if (currentSegment === winningSegment && frames > segments.length) {
                    progress = duration / upTime
                    angleDelta =
                        maxSpeed * Math.sin((progress * Math.PI) / 2 + Math.PI / 2)
                    progress = 1
                } else {
                    progress = duration / downTime
                    angleDelta =
                        maxSpeed * Math.sin((progress * Math.PI) / 2 + Math.PI / 2)
                }
            } else {
                progress = duration / downTime
                angleDelta = maxSpeed * Math.sin((progress * Math.PI) / 2 + Math.PI / 2)
            }
            if (progress >= 1) finished = true
        }

        angleCurrent += angleDelta
        while (angleCurrent >= Math.PI * 2) angleCurrent -= Math.PI * 2
        if (finished) {
            setFinished(true)
            onFinished(currentSegments)
            clearInterval(timerHandle)
            timerHandle = 0
            angleDelta = 0
        }
    }

    const wheelDraw = () => {
        clear()
        // drawWheel()
        // drawNeedle()
    }

    const draw = () => {
        clear()
        // drawWheel()
        // drawNeedle()
    }
    const drawSegment = (key, lastAngle, angle) => {
        const ctx = canvasContext
        const value = segments[key].name
        ctx.save()
        ctx.beginPath()
        ctx.moveTo(centerX, centerY)
        ctx.arc(centerX, centerY, size, lastAngle, angle, false)
        ctx.lineTo(centerX, centerY)
        ctx.closePath()
        ctx.fillStyle = segColors[key]
        ctx.fill()
        ctx.stroke()
        ctx.save()
        ctx.translate(centerX, centerY)
        ctx.rotate((lastAngle + angle) / 2)
        ctx.fillStyle = contrastColor
        ctx.font = 'bold 1em ' + fontFamily
        ctx.fillText(value.substr(0, 21), size / 2 + 20, 0)
        ctx.restore()
    }

    const drawWheel = () => {
        const ctx = canvasContext
        let lastAngle = angleCurrent
        const len = segments.length
        const PI2 = Math.PI * 2
        ctx.lineWidth = 1
        ctx.strokeStyle = primaryColor
        ctx.textBaseline = 'middle'
        ctx.textAlign = 'center'
        ctx.font = '1em ' + fontFamily
        for (let i = 1; i <= len; i++) {
            const angle = PI2 * (i / len) + angleCurrent
            drawSegment(i - 1, lastAngle, angle)
            lastAngle = angle
        }

        // Draw a center circle
        ctx.beginPath()
        ctx.arc(centerX, centerY, 50, 0, PI2, false)
        ctx.closePath()
        ctx.fillStyle = primaryColor
        ctx.lineWidth = 10
        ctx.strokeStyle = contrastColor
        ctx.fill()
        ctx.font = 'bold 1em ' + fontFamily
        ctx.fillStyle = contrastColor
        ctx.textAlign = 'center'
        ctx.fillText(buttonText, centerX, centerY + 3)
        ctx.stroke()

        // Draw outer circle
        ctx.beginPath()
        ctx.arc(centerX, centerY, size, 0, PI2, false)
        ctx.closePath()

        ctx.lineWidth = 10
        ctx.strokeStyle = primaryColor
        ctx.stroke()
    }

    const drawNeedle = () => {
        const ctx = canvasContext
        ctx.lineWidth = 1
        ctx.strokeStyle = contrastColor
        ctx.fileStyle = "contrastColor"
        ctx.beginPath()
        ctx.moveTo(centerX + 20, centerY - 50)
        ctx.lineTo(centerX - 20, centerY - 50)
        ctx.lineTo(centerX, centerY - 70)
        ctx.closePath()
        ctx.fill()
        const change = angleCurrent + Math.PI / 2
        let i =
            segments.length -
            Math.floor((change / (Math.PI * 2)) * segments.length) -
            1
        if (i < 0) i = i + segments.length
        ctx.textAlign = 'center'
        ctx.textBaseline = 'middle'
        ctx.fillStyle = "white"
        ctx.font = 'bold 1.5em ' + fontFamily
        currentSegment = segments[i].name
        currentSegments = segments[i]
        isStarted && ctx.fillText(currentSegment, centerX + 10, centerY + size + 80)
        !isStarted && ctx.fillText("กดเพื่อหมุน ", centerX + 10, centerY + size + 80)
        ctx.fillText("คุณเหลืออีก " + rounds + " ครั้ง", centerX, centerY + size + 180)
       //  handlewheel(3);
    }
    const clear = () => {
        const ctx = canvasContext
        ctx.clearRect(0, 0, width, height)
       //  handlewheel(3);
    }
    return (
        <div id='wheel'>
            <canvas
                id='canvas'
                width={width}
                height={height}
                style={{
                    pointerEvents: isFinished && isOnlyOnce ? 'none' : 'auto'
                }}
            />
        </div>
    )
}
export default WheelComponent